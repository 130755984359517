import React, { useEffect, useRef, useState } from 'react';

const FadeInMove = ({ children, direction = 'horizontal', distance, duration = 0.5 }) => {
    const elementRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target); // Stop observing once visible
                }
            });
        }, {
            threshold: 0.1 // Element must be at least 10% visible
        });

        if (elementRef.current) {
            observer.observe(elementRef.current);
        }

        return () => {
            if (elementRef.current) {
                observer.unobserve(elementRef.current);
            }
        };
    }, []);

    // Dynamically set the initial transform based on direction (horizontal/vertical)
    const initialTransform = direction === 'horizontal'
        ? `translate(${distance}, 0)`  // Only move on the X axis
        : `translate(0, ${distance})`;  // Only move on the Y axis

    const dynamicStyle = {
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? 'translate(0, 0)' : initialTransform,
        transition: `all ${duration}s ease`,
    };

    return (
        <div ref={elementRef} style={dynamicStyle}>
            {children}
        </div>
    );
};

export default FadeInMove;
